import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Img14 from "../assets/img/Img14.png";
import emailIcon from "../assets/img/Email-svg.png";
import phoneIcon from "../assets/img/Phone-svg.png";
import whatsAppIcon from '../assets/img/whatsapp-svg.png'
import addressIcon from '../assets/img/LocationSvgIcon.png'
import emailSvg from '../assets/img/MailSvgIcon.png'
import phoneSvg from '../assets/img/phoneSvgIcon.png'
import companySvg from '../assets/img/companySvg.png'
import "../assets/css/Contact.css";

const Loader = () => (
  <div className="loader-overlay">
    <div className="loader"></div>
    <p style={{fontSize:'20px',fontWeight:600,color:'#fff',margin:'1rem 0',textAlign:'center'}}>Loading, please wait. We’re almost there..!</p>
  </div>
);

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    from_message: '',
  });
  
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const contactData = {
      name: formData.from_name || null,
      email: formData.from_email || null,
      phone: formData.phone || null,
      zone: formData.from_timezone || 'UTC',
      time: formData.form_time || null,
      date: formData.form_date || new Date(), // Ensure `date` is populated with a valid value
      leadSource: 'website_contact_form',
      message: formData.from_message || '',
      status: 'Booked',
      remarks: 'Not yet contacted',
      bookedDate: new Date(),
      updatedBy: 'No update'
    };
    
  
    try {
      const response = await axios.post('https://techblume-backendcrm.onrender.com/api/bookings/add', contactData); // Localhost API URL
      alert('Thank you! Your message has been sent to the Techblume team.');
      console.log(response.data);
  
      setFormData({
        from_name: '',
        from_email: '',
        from_message: '',
      });
    } catch (error) {
      console.error('Error sending message:', error);
  
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
  
      alert('Sorry! There was an error sending your message. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto'; // Enable scrolling
  }, []);

  return (
    <>
      <Helmet>
        <title>Techblume | Contact Us</title>
      </Helmet>

      {loading && <Loader />} {/* Show loader when loading */}

      <div className="contact-page">
        <div className="contact-page1">
          <img src={Img14} alt="Contact banner" />
          <section>
            <h1>Contact Us</h1>
            <p>
              Techblume is a cutting-edge online Bootcamp offering expert-led classes in web development, data science, and more. Perfect for both beginners and seasoned professionals, our flexible and interactive courses provide hands-on experience and practical knowledge to advance your tech career. Join us to enhance your skills with guidance from industry experts.
            </p>
          </section>
        </div>
        
        <div className='contact-text1'>
          <h1>Leave us a Message</h1>
          <p>So our team can reach out to you on time</p>
        </div>
        
        <div className="contact-page2">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="from_message"
                value={formData.from_message}
                onChange={handleChange}
                placeholder="Enter your message"
                required
              />
            </div>

            <button type="submit" className="submit-btn">Submit</button>
          </form>

          <div className="contacts">
            <section>
              <main>
                <img src={emailIcon} alt="Email" />
              </main>
              <p>
                <a href="mailto:support@techblume.in" target="_blank" rel="noreferrer">support@techblume.in</a>
              </p>
            </section>

            <section>
              <main>
                <img src={phoneIcon} alt="Phone" />
              </main>
              <p>
                <a href="tel:13029478773" target="_blank" rel="noreferrer">+1 3029478773</a>
              </p>
            </section>

            <section>
              <main>
                <img src={whatsAppIcon} alt="whatsapp" />
              </main>
              <p>
              <a href="https://wa.me/919686430320" target="_blank" rel="noreferrer">+91 9686430320</a>

              </p>
            </section>
          </div>
        </div>

        <div className='contact-text1'>
          <h1>A Worldwide Network of Excellence</h1>
          <p>Collaborating globally to deliver top-tier learning and innovative solutions</p>
        </div>

        <div className='location-container'>
        <section>
          <h1>USA</h1>
          <p className='location'>
            <img src={companySvg} alt="addressIcon" />
            <span style={{fontWeight:600}}>Techblume Software Solutions Inc</span>
          </p>
          <p className='location'>
            <img src={addressIcon} alt="addressIcon" />
            <span>16192, Coastal Highway, Lewes Delaware, 19958</span>
          </p>
          <p className='email'>
            <img src={emailSvg} alt="emailIcon" />
            <span>support@techblume.in</span>
          </p>
          <p className='phone'>
            <img src={phoneSvg} alt="phoneIcon" />
            <span>+1 3029478773</span>
          </p>
        </section>

        <section>
          <h1>INDIA</h1>
          <p className='location'>
            <img src={companySvg} alt="addressIcon" />
            <span style={{fontWeight:600}}>Techblume Software Solutions Pvt Ltd</span>
          </p>
          <p className='location'>
            <img src={addressIcon} alt="addressIcon" />
            <span>613, AECS Layout, Singasandra, Bangalore, 560068
</span>
          </p>
          <p className='email'>
            <img src={emailSvg} alt="emailIcon" />
            <span>support@techblume.in</span>
          </p>
          <p className='phone'>
            <img src={phoneSvg} alt="phoneIcon" />
            <span>+91 9686430320</span>
          </p>
        </section>
        </div>
      </div>
    </>
  );
};

export default Contact;
